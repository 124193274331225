//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as echarts from "echarts";
export default {
  props: ["_currentStore", "_brandCode"],
  name: "Vip",
  data() {
    return {
      brandCode: this._brandCode,
      currentStore: this._currentStore,
      requestData: {},
      thisModelLoading: false,

      vipLoading: false,
      dayUpLoading: false,
      sexLoading: false,
      ageLoading: false,
      activeLoading: false,
      marketLoading: false,

      active: 0,
      chartIns: {
        viptrend: null,
        "gender-pie": null,
        "age-bar": null,
        "frequency-bar": null,
        "style-bar": null,
        "level-bar": null,
        "activation-bar": null,
        "aveprice-bar": null,
        "apr-bar": null,
      },
      marketAttrData: null,
      ageData: null,
      frequencyData: null,
      genderData: null,
      levelData: null,
      aprData: null,
      avepriceData: null,

      currentVipSum: 0,
      dayIncrease: 0,
      monthIncrease: 0,
      yearIncrease: 0,
      saleFrequencyData: {
        customerNum: {
          VOL_saleMember_1and2year: 0,
          VOL_saleMember_saleCount_1: 0,
          VOL_saleMember_saleCount_2: 0,
          VOL_saleMember_saleCount_3: 0,
          VOL_saleMember_saleCount_4: 0,
          VOL_saleMember_saleCount_5: 0,
          VOL_saleMember_saleCount_6: 0,
          VOL_saleMember_saleCount_7: 0,
          VOL_saleMember_saleCount_8: 0,
          VOL_saleMember_saleCount_9: 0,
          VOL_saleMember_saleCount_10: 0,
          VOL_saleMember_saleCount_10Over: 0,
        },
        vipNum: 0,
        realVipNum: 0,
        realVipNumPer: 0,
        activeVipNum: 0,
        activeVipNumPer: 0,
        hightActiveVipNum: 0,
        hightActiveVipNumPer: 0,
      },
      firstOrderRepurchaseData: {
        date: "",
        VOL_NewCustomersBill: 0, //新客开单数
        VOL_RepurchaseHalfYear: 0, //半年复购数
        VOL_Repurchase3Month: 0, //三个月复购人数
        VAL_NewCustomersBill: 0, //同期新客开单数
        VAL_RepurchaseHalfYear: 0, //同期半年复购数
        VAL_Repurchase3Month: 0, //同期三个月复购人数
      },
      birthdayRepurchaseAnalysisData: {
        VOL_TwoYearsBirthdayMember: 0,
        VOL_BirthdaySaleMember: 0,
        RATE_BirthdaySaleMember: 0,
        VOL_BirthdayMember: 0,
      },
    };
  },
  created() {
    this.requestData = [
      {
        SIGN: "I",
        OPTION: "EQ",
        PARAMETER: "ZLOY_PROG_003",
        LOW: this.brandCode + "LOYPRO",
        HIGH: "",
        LOW_FLAG: "1",
      },
      {
        SIGN: "I",
        OPTION: "EQ",
        PARAMETER: "ZV_MD04",
        LOW: this.currentStore,
        HIGH: "",
        LOW_FLAG: "1",
      },
    ];

    this.getVipIncreasement();
    this.getVipDayIncreasement();
    this.getAgeData();
    this.getLevelData();
    this.getGender();
    this.fetchMarketAttrRatio();
    this.getVipActivation();
    this.getVipAll()
  },
  mounted() {
    console.log('echarts',echarts);


    // this.drawGender();
    // this.drawAge();
    // this.drawFrequency();
    // this.drawStyle();
    // this.drawLevel();
    // this.drawActivation();
    // this.drawAveprice();
    // this.drawApr();

    window.document.title = "门店业绩";
  },
  activated() {
    window.document.title = "门店业绩";
  },
  methods: {
    isExist(id) {
      return document.getElementById(id);
    },
    updateParams(store, brand) {
      console.log(store);
      this.currentStore = store;
      this.brandCode = brand;
    },
    updateData() {
      this.getVipIncreasement();
      this.getVipDayIncreasement();
      this.getAgeData();
      this.getLevelData();
      this.getGender();
      this.fetchMarketAttrRatio();
      this.getVipActivation();
      this.getVipAll();
    },
    showChart() {
      let that = this;
      switch (that.active) {
        case 0:
          // that.drawVipTrend();
          break;
        case 1:
          setTimeout(function () {
            if (that.genderData) {
              that.drawGender(that.genderData);
            }
            if (that.ageData) {
              that.drawAge(that.ageData);
            }
            if (that.frequencyData) {
              that.drawFrequency(
                that.frequencyData.yData,
                that.frequencyData.xData
              );
            }
            if (that.styleData) {
              that.drawStyle(that.styleData.yData, that.styleData.xData);
            }
          }, 800);
          break;
        case 2:
          setTimeout(function () {
            if (that.aprData) {
              that.drawAveprice(
                that.avepriceData.yData,
                that.avepriceData.xData
              );
              that.drawApr(that.aprData.yData, that.aprData.xData);
            }
            if (that.levelData) {
              that.drawLevel(that.levelData.yData, that.levelData.xData);
            }
            if (that.activationData) {
              that.drawActivation(
                that.activationData.sData,
                that.activationData.xData
              );
            }
          }, 800);
          break;
        case 3:
          that.getSaleFrequencyData();
          that.getFirstOrderRepurchaseData();
          that.getBirthdayRepurchaseAnalysisData();
          break;
      }
    },

    getVipIncreasement() {
      this.vipLoading = true;
      let requestData = [
        {
          SIGN: "I",
          OPTION: "BT",
          PARAMETER: "Z0CALDAY_MVIM_001",
          LOW: this.$moment().startOf("year").format("YYYYMMDD"),
          HIGH: this.$moment().format("YYYYMMDD"),
          LOW_FLAG: "1",
        },
        {
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "Z0CUSTOMER_MVOO_002",
          LOW: this.currentStore,
          HIGH: "",
          LOW_FLAG: "1",
        },     {
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "Z0INDUSTRY_MVMM_003",
          LOW: this.brandCode,
          HIGH: "",
          LOW_FLAG: "0",
        },
      ];

      this.axios
        .post(
          "/" + this.$ajaxPrefix.consumer + "/bw/BwApi/storeanalysis_customer_new_month",
          requestData
        )
        .then((res) => {
          console.log(res);
          this.vipLoading = false;
          if (res && res.data && res.data.data) {
            let temp = res.data.data;
            if (temp.length > 0) {
              var currentData=[];
              var lastData=[];
              temp.map((item)=>{
                if(item.member_num!=""){
                  currentData.push(item.member_num)
                }
                if(item.yoy_member_num!=""){
                  lastData.push(item.yoy_member_num)
                }
              })
              this.drawVipTrend(currentData,lastData)
              // this.currentVipSum = temp[temp.length - 1].vipCount;
              this.monthIncrease = currentData[currentData.length - 1];
              currentData.reduce((pre,cur)=>{
                this.yearIncrease=Number(pre)+Number(cur)
                return this.yearIncrease;
              })
              //this.getLastVipIncreasement(temp);
            }else{
              this.vipLoading = false;
            }
          }
        });
    },
    // getLastVipIncreasement(currentData) {
    //   let requestData = [
    //     {
    //       SIGN: "I",
    //       OPTION: "EQ",
    //       PARAMETER: "ZVAR_YEAR15",
    //       LOW: this.$moment()
    //         .subtract(1, "months")
    //         .subtract(1, "years")
    //         .format("yyyy"),
    //       HIGH: "",
    //       LOW_FLAG: "0",
    //     },
    //     {dayres
    //       SIGN: "I",
    //       OPTION: "EQ",
    //       PARAMETER: "ZV_MD01",
    //       LOW: this.currentStore,
    //       HIGH: "",
    //       LOW_FLAG: "1",
    //     },
    //   ];
    //   console.log('currentData',currentData);
    //   let current = [];
    //   currentData.forEach((item) => {
    //     current.push(item.vipIncreaseCount);
    //   });

    //   this.axios
    //     .post(
    //       "/" + this.$ajaxPrefix.consumer + "/bw/BwApi/storeVipIncreasefor700",
    //       requestData
    //     )
    //     .then((res) => {
    //       this.vipLoading = false;
    //       if (res && res.data && res.data.data) {
    //         let temp = res.data.data;
    //         let last = [];
    //         temp.forEach((item) => {
    //           last.push(item.vipIncreaseCount);
    //         });
    //         this.drawVipTrend(current, last);
    //         this.yearIncrease =
    //           this.currentVipSum - temp[temp.length - 1].vipCount;
    //       }
    //     });
    // },
    getVipDayIncreasement() {
      this.dayUpLoading = true;
      let requestData = [
        {
          SIGN: "I",
          OPTION: "BT",
          PARAMETER: "Z0CALDAY_MVIM_001",
          LOW: this.$moment().subtract(1, "days").format("YYYYMMDD"),
          HIGH: this.$moment().subtract(1, "days").format("YYYYMMDD"),
          LOW_FLAG: "1",
        },
        {
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "Z0CUSTOMER_MVOO_002",
          LOW: this.currentStore,
          HIGH: "",
          LOW_FLAG: "1",
        },     {
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "Z0INDUSTRY_MVMM_003",
          LOW: this.brandCode,
          HIGH: "",
          LOW_FLAG: "0",
        },
      ];
      this.axios
        .post(
          "/" + this.$ajaxPrefix.consumer + "/bw/BwApi/storeanalysis_customer_new_month",
          requestData
        )
        .then((res) => {
          this.dayUpLoading = false;
          if (res && res.data && res.data.data) {
             const dayres=res.data.data;
             dayres.map((item)=>{
              if(item.member_num!=""){
                this.dayIncrease=item.member_num
                }
             })
         
          }
        });
    },
    getVipAll() {
      this.dayUpLoading = true;
      let requestData = [
        {
          SIGN: "I",
          OPTION: "BT",
          PARAMETER: "Z0CALDAY_MVIM_001",
          LOW: '20000101',
          HIGH: this.$moment().format("YYYYMMDD"),
          LOW_FLAG: "1",
        },
        {
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "Z0CUSTOMER_MVOO_002",
          LOW: this.currentStore,
          HIGH: "",
          LOW_FLAG: "1",
        },     {
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "Z0INDUSTRY_MVMM_003",
          LOW: this.brandCode,
          HIGH: "",
          LOW_FLAG: "0",
        },
      ];
      this.axios
        .post(
          "/" + this.$ajaxPrefix.consumer + "/bw/BwApi/storeVipIncreasefor700",
          requestData
        )
        .then((res) => {
          this.dayUpLoading = false;
          if (res && res.data && res.data.data) {
             const dayres=res.data.data;
             this.currentVipSum=dayres[0].vipIncreaseCount
         
          }
        });
    },
    drawVipTrend(currentData, lastData) {
      let option = {
        title: {
          text: "会员增长趋势",
          top: 10,
        },
        color: ["#4f79d3", "#67ba7d", "#ffd143"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          data: ["当期", "同期"],
          top: "5%",
          left: "55%",
        },
        xAxis: {
          type: "category",
          data: [
            "一月",
            "二月",
            "三月",
            "四月",
            "五月",
            "六月",
            "七月",
            "八月",
            "九月",
            "十月",
            "十一月",
            "十二月",
          ],
          axisLabel: {
            rotate: 40,
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: currentData,
            type: "line",
            name: "当期",
          },
          {
            data: lastData,
            type: "line",
            name: "同期",
          },
        ],
      };

      this.drawLine("viptrend", option);
    },
    getGender() {
      this.genderData = null;
      this.thisModelLoading = true;
      let seriesData = [];
      var data = this.requestData;
      this.sexLoading = true;
      this.axios
        .post(
          "/" + this.$ajaxPrefix.consumer + "/bw/BwApi/getMemberSexRatio",
          data
        )
        .then((res) => {
          if (res.data && res.data.result && res.data.data) {
            let result = res.data.data;
            for (var i = 0; i < result.length; i++) {
              seriesData.push({
                value: result[i].memberNum,
                name: result[i].sexName,
                ratio: result[i].ratio,
              });
            }
            this.genderData = seriesData;
            if (this.isExist("gender-pie")) {
              this.drawGender(seriesData);
            }
          }
          this.sexLoading = false;
        });
    },
    //画性别
    drawGender(seriesData) {
      let oData = {
        title: "会员性别占比",
        label: "会员数",
      };
      this.drawPie("gender-pie", seriesData, oData);
    },
    //获取会员活跃度
    getVipActivation() {
      let data = this.requestData;
      this.activeLoading = true;
      this.axios
        .post(
          "/" +
            this.$ajaxPrefix.consumer +
            "/bw/BwApi/getMemberActivationRatio",
          data
        )
        .then((res) => {
          console.log("会员活跃度占比", res);
          if (res && res.data && res.data.data) {
            let temp = res.data.data;
            let sData = [];
            let xData = [];
            temp.forEach((item) => {
              sData.push(Number(Number(item.ratio).toFixed(2)));
              xData.push(item.activationName);
            });
            this.activationData = {
              sData,
              xData,
            };
            if (this.isExist("activation-bar")) {
              this.drawActivation(sData, xData);
            }
          }
          this.activeLoading = false;
        });
    },
    //获取市场营销数据
    fetchMarketAttrRatio() {
      // if (this.marketAttrData) {
      //   this.drawFrequency();
      //   return;
      // }
      this.thisModelLoading = true;
      this.marketLoading = true;
      let data = this.requestData;
      this.axios
        .post(
          "/" +
            this.$ajaxPrefix.consumer +
            "/bw/BwApi/getMemberMarketAttrRatio",
          data
        )
        .then((res) => {
          if (res.data && res.data.result && res.data.data) {
            let temp = res.data.data;
            // 服装购买频次占比
            let xData = [];
            let yData = [];
            for (let i = 0; i < temp.length; i++) {
              if (
                temp[i]["featuresTitle"] === "SAP_0000000435" &&
                temp[i]["featuresValueName"] !== "结果"
              ) {
                xData.push(temp[i]["featuresValueName"]);
                yData.push(Number(Number(temp[i]["ratio"]).toFixed(2)));
              }
            }

            this.frequencyData = {
              yData,
              xData,
            };
            if (this.isExist("frequency-bar")) {
              this.drawFrequency(yData, xData);
            }

            // 着装风格偏好占比
            xData = [];
            yData = [];
            for (let i = 0; i < temp.length; i++) {
              if (
                temp[i]["featuresTitle"] === "SAP_0000000136" &&
                temp[i]["featuresValueName"] !== "结果"
              ) {
                xData.push(temp[i]["featuresValueName"]);
                yData.push(Number(temp[i]["ratio"]).toFixed(2));
              }
            }
            this.styleData = {
              yData,
              xData,
            };
            if (this.isExist("style-bar")) {
              this.drawStyle(yData, xData);
            }

            // 客单价
            xData = [];
            yData = [];
            for (let i = 0; i < temp.length; i++) {
              if (
                temp[i]["featuresTitle"] === "SAP_9000000002" &&
                temp[i]["featuresValueName"] !== "结果"
              ) {
                xData.push(temp[i]["featuresValueName"]);
                yData.push(Number(temp[i]["ratio"]).toFixed(2));
              }
            }
            this.avepriceData = {
              yData,
              xData,
            };
            if (this.isExist("aveprice-bar")) {
              this.drawAveprice(yData, xData);
            }

            // 连带率
            xData = [];
            yData = [];
            for (let i = 0; i < temp.length; i++) {
              if (
                temp[i]["featuresTitle"] === "SAP_9000000001" &&
                temp[i]["featuresValueName"] !== "结果"
              ) {
                xData.push(temp[i]["featuresValueName"]);
                yData.push(Number(temp[i]["ratio"]).toFixed(2));
              }
            }
            this.aprData = { xData, yData };

            if (this.isExist("apr-bar")) {
              this.drawApr(yData, xData);
            }
          }
          this.marketLoading = false;
          this.thisModelLoading = false;
        });
    },
    //画频次
    drawFrequency(sData, xData) {
      let oData = {
        title: "会员购物频次占比",
        seriesData: sData,
        xAxisData: xData,
      };

      this.drawBar("frequency-bar", oData, {
        xname: "频次分布",
        yname: "占比(%)",
      });
    },
    getAgeData() {
      this.ageLoading = true;
      this.ageData = null;
      let oData = {
        title: "会员年龄分布",
        seriesData: [],
        xAxisData: [],
      };
      this.thisModelLoading = true;
      let data = this.requestData;
      this.axios
        .post(
          "/" + this.$ajaxPrefix.consumer + "/bw/BwApi/getMemberAgeRatio",
          data
        )
        .then((res) => {
          if (res.data && res.data.result && res.data.data) {
            let result = res.data.data;
            for (var i = 0; i < result.length; i++) {
              oData.seriesData.push(result[i].memberNum);
              oData.xAxisData.push(result[i].name);
            }
            this.ageData = oData;
            if (this.isExist("age-bar")) {
              this.drawAge(oData);
            }
          }
          this.ageLoading = false;
        });
    },
    getLevelData() {
      this.levelLoading = true;
      this.levelData = null;
      let data = this.requestData,
        xData = [],
        yData = [];

      this.axios
        .post(
          "/" + this.$ajaxPrefix.consumer + "/bw/BwApi/getMemberLevelRatio",
          data
        )
        .then((res) => {
          console.log(res);
          if (res && res.data && res.data.result && res.data.data) {
            let result = res.data.data;
            for (let i = 0; i < result.length; i++) {
              xData.push(result[i]["levelName"]);
              yData.push(Number(result[i]["memberNum"]));
            }
          }
          this.levelData = {
            yData,
            xData,
          };
          if (this.isExist("level-bar")) {
            this.drawLevel(yData, xData);
          }
          this.levelLoading = false;
        });
    },
    //画年龄分布
    drawAge(oData) {
      this.drawBar("age-bar", oData, { yname: "会员数量", xname: "年龄分布" });
    },
    //画着装风格
    drawStyle(sData, xData) {
      let oData = {
        title: "会员着装风格占比",
        seriesData: sData,
        xAxisData: xData,
      };

      this.drawBar("style-bar", oData, {
        xname: "着装风格",
        yname: "风格占比(%)",
      });
    },
    //画等级
    drawLevel(sData, xData) {
      let oData = {
        title: "会员等级分布",
        seriesData: sData,
        xAxisData: xData,
      };

      this.drawBar("level-bar", oData, {
        xname: "会员等级",
        yname: "会员数量",
      });
    },

    //会员活跃度
    drawActivation(sData, xData) {
      let oData = {
        title: "会员活跃度",
        seriesData: sData,
        xAxisData: xData,
      };

      this.drawBar("activation-bar", oData, {
        xname: "活跃度",
        yname: "占比(%)",
      });
    },
    //客单价
    drawAveprice(sData, xData) {
      let oData = {
        title: "客单价占比",
        seriesData: sData,
        xAxisData: xData,
      };

      this.drawBar("aveprice-bar", oData, {
        xname: "客单价",
        yname: "占比(%)",
      });
    },

    //连带率占比
    drawApr(sData, xData) {
      let oData = {
        title: "连带率占比",
        seriesData: sData,
        xAxisData: xData,
      };

      this.drawBar("apr-bar", oData, { xname: "连带率", yname: "占比(%)" });
    },
    drawLine(el, option) {
      if(this.chartIns[el]){
        this.chartIns[el].resize();
        return
      }
      this.chartIns[el] = echarts.init(document.getElementById(el));
      //  let color = ["#1976d2", "#2196f3"];

      this.chartIns[el].setOption(option);
    },
    drawPie(el, seriesSata, oData) {
  
      if(this.chartIns[el]){
        this.chartIns[el].resize();
        return
      }
      this.chartIns[el] = echarts.init(document.getElementById(el));
      let option = {
        title: {
          text: oData.title,
          top: 0,
        },
        color: ["#4f79d3", "#67ba7d", "#ffd143"],
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          left: "center",
          bottom: 0,
        },
        series: [
          {
            name: oData.label,
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: seriesSata,
          },
        ],
      };
      this.chartIns[el].setOption(option);
    },
    drawBar(el, oData, xyname) {
      if(this.chartIns[el]){
        this.chartIns[el].resize();
        return
      }
      this.chartIns[el] = echarts.init(document.getElementById(el));
      //  let color = ["#1976d2", "#2196f3"];
      let option = {
        title: {
          text: oData.title,
          top: 10,
        },
        tooltip: {
          trigger: "axis",
        },
        color: ["#4f79d3", "#67ba7d", "#ffd143"],
        xAxis: {
          type: "category",
          data: oData.xAxisData,
          axisLabel: {
            rotate: 40,
          },
          name: xyname && xyname.xname ? xyname.xname : "",
        },
        yAxis: {
          type: "value",
          name: xyname && xyname.yname ? xyname.yname : "",
        },
        grid: {
          left: "15%",
          top: "26%",
          right: "18%",
        },
        series: [
          {
            data: oData.seriesData,
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
          },
        ],
      };
      this.chartIns[el].setOption(option);
      this.chartIns[el].resize()
    },
    getSaleFrequencyData() {
      this.saleFrequencyData = {
        customerNum: {
          VOL_saleMember_1and2year: 0,
          VOL_saleMember_saleCount_1: 0,
          VOL_saleMember_saleCount_2: 0,
          VOL_saleMember_saleCount_3: 0,
          VOL_saleMember_saleCount_4: 0,
          VOL_saleMember_saleCount_5: 0,
          VOL_saleMember_saleCount_6: 0,
          VOL_saleMember_saleCount_7: 0,
          VOL_saleMember_saleCount_8: 0,
          VOL_saleMember_saleCount_9: 0,
          VOL_saleMember_saleCount_10: 0,
          VOL_saleMember_saleCount_10Over: 0,
        },
        vipNum: 0,
        realVipNum: 0,
        realVipNumPer: 0,
        activeVipNum: 0,
        activeVipNumPer: 0,
        hightActiveVipNum: 0,
        hightActiveVipNumPer: 0,
      };
      let requestData = [
        {
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "Z0CALMONTH_MVSM_001",
          LOW: this.$moment().format("yyyyMM"),
          HIGH: "",
          LOW_FLAG: "0",
        },
        {
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "Z0INDUSTRY_MVMM_003",
          LOW: this.brandCode,
          HIGH: "",
          LOW_FLAG: "0",
        },
        {
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "Z0CUSTOMER_MVMO_001",
          LOW: this.currentStore,
          HIGH: "",
          LOW_FLAG: "0",
        },
      ];

      //  let current = [];
      this.axios
        .post(
          "/" + this.$ajaxPrefix.consumer + "/bw/BwApi/MemberStructureAnalysis",
          requestData
        )
        .then((res) => {
         
          this.vipLoading = false;
          if (res && res.data && res.data.data) {
            let temp = res.data.data;
            for (let index = 0; index < temp.length; index++) {
              let element = temp[index];
              this.saleFrequencyData.customerNum.VOL_saleMember_1and2year +=
                Number(element.VOL_saleMember_1and2year || 0);
              this.saleFrequencyData.customerNum.VOL_saleMember_saleCount_1 +=
                Number(element.VOL_saleMember_saleCount_1 || 0);
              this.saleFrequencyData.customerNum.VOL_saleMember_saleCount_2 +=
                Number(element.VOL_saleMember_saleCount_2 || 0);
              this.saleFrequencyData.customerNum.VOL_saleMember_saleCount_3 +=
                Number(element.VOL_saleMember_saleCount_3 || 0);
              this.saleFrequencyData.customerNum.VOL_saleMember_saleCount_4 +=
                Number(element.VOL_saleMember_saleCount_4 || 0);
              this.saleFrequencyData.customerNum.VOL_saleMember_saleCount_5 +=
                Number(element.VOL_saleMember_saleCount_5 || 0);
              this.saleFrequencyData.customerNum.VOL_saleMember_saleCount_6 +=
                Number(element.VOL_saleMember_saleCount_6 || 0);
              this.saleFrequencyData.customerNum.VOL_saleMember_saleCount_7 +=
                Number(element.VOL_saleMember_saleCount_7 || 0);
              this.saleFrequencyData.customerNum.VOL_saleMember_saleCount_8 +=
                Number(element.VOL_saleMember_saleCount_8 || 0);
              this.saleFrequencyData.customerNum.VOL_saleMember_saleCount_9 +=
                Number(element.VOL_saleMember_saleCount_9 || 0);
              this.saleFrequencyData.customerNum.VOL_saleMember_saleCount_10 +=
                Number(element.VOL_saleMember_saleCount_10 || 0);
              this.saleFrequencyData.customerNum.VOL_saleMember_saleCount_10Over +=
                Number(element.VOL_saleMember_saleCount_10Over || 0);
            }
            
            for (let key in this.saleFrequencyData.customerNum) {
              this.saleFrequencyData.vipNum +=
                this.saleFrequencyData.customerNum[key];
              if (key != "VOL_saleMember_1and2year") {
                this.saleFrequencyData.realVipNum +=
                  this.saleFrequencyData.customerNum[key];
              }
              if (
                key != "VOL_saleMember_1and2year" &&
                key != "VOL_saleMember_saleCount_1"
              ) {
                this.saleFrequencyData.activeVipNum +=
                  this.saleFrequencyData.customerNum[key];
              }
              if (
                key != "VOL_saleMember_1and2year" &&
                key != "VOL_saleMember_saleCount_1" &&
                key != "VOL_saleMember_saleCount_2" &&
                key != "VOL_saleMember_saleCount_3"
              ) {
                this.saleFrequencyData.hightActiveVipNum +=
                  this.saleFrequencyData.customerNum[key];
              }
            }
            this.saleFrequencyData.realVipNumPer =
              this.saleFrequencyData.realVipNum / this.saleFrequencyData.vipNum; //有效VIP占比
            this.saleFrequencyData.activeVipNumPer =
              this.saleFrequencyData.activeVipNum /
              this.saleFrequencyData.realVipNum; //活跃VIP占比
            this.saleFrequencyData.hightActiveVipNumPer =
              this.saleFrequencyData.hightActiveVipNum /
              this.saleFrequencyData.realVipNum; //高效VIP占比
           
          }
        });
    },
    getFirstOrderRepurchaseData() {
      this.firstOrderRepurchaseData = {
        date: this.$moment().subtract(6, "months").format("yyyy-MM"),
        VOL_NewCustomersBill: 0, //新客开单数
        VOL_RepurchaseHalfYear: 0, //半年复购数
        VOL_Repurchase3Month: 0, //三个月复购人数
        VAL_NewCustomersBill: 0, //同期新客开单数
        VAL_RepurchaseHalfYear: 0, //同期半年复购数
        VAL_Repurchase3Month: 0, //同期三个月复购人数
      };

      let requestData = [
        {
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "Z0CALMONTH_MVSM_002",
          LOW: this.$moment().subtract(6, "months").format("yyyyMM"),
          HIGH: "",
          LOW_FLAG: "0",
        },
        {
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "Z0INDUSTRY_MVMM_003",
          LOW: this.brandCode,
          HIGH: "",
          LOW_FLAG: "0",
        },
        {
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "Z0CUSTOMER_MVMO_001",
          LOW: this.currentStore,
          HIGH: "",
          LOW_FLAG: "0",
        },
      ];

      //  let current = [];
      this.axios
        .post(
          "/" + this.$ajaxPrefix.consumer + "/bw/BwApi/getFirstOrderRepurchase",
          requestData
        )
        .then((res) => {
          this.vipLoading = false;
          if (res && res.data && res.data.data) {
            let temp = res.data.data;
            for (let index = 0; index < temp.length; index++) {
              let element = temp[index];
              this.firstOrderRepurchaseData.VOL_NewCustomersBill += Number(
                element.VOL_NewCustomersBill
              );
              this.firstOrderRepurchaseData.VOL_RepurchaseHalfYear += Number(
                element.VOL_RepurchaseHalfYear
              );
              this.firstOrderRepurchaseData.VOL_Repurchase3Month += Number(
                element.VOL_Repurchase3Month
              );
              this.firstOrderRepurchaseData.VAL_NewCustomersBill += Number(
                element.YA_VOL_NewCustomersBill
              );
              this.firstOrderRepurchaseData.VAL_RepurchaseHalfYear += Number(
                element.YA_VOL_RepurchaseHalfYear
              );
              this.firstOrderRepurchaseData.VAL_Repurchase3Month += Number(
                element.YA_VOL_Repurchase3Month
              );
            }
           
          }
        });
    },
    getBirthdayRepurchaseAnalysisData() {
      this.birthdayRepurchaseAnalysisData = {
        VOL_TwoYearsBirthdayMember: 0,
        VOL_BirthdaySaleMember: 0,
        RATE_BirthdaySaleMember: 0,
        VOL_BirthdayMember: 0,
      };
      let requestData = [
        {
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "Z0CALMONTH_MVSM_001",
          LOW: this.$moment().format("yyyyMM"),
          HIGH: "",
          LOW_FLAG: "0",
        },
        {
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "Z0INDUSTRY_MVMM_003",
          LOW: this.brandCode,
          HIGH: "",
          LOW_FLAG: "0",
        },
        {
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "Z0CUSTOMER_MVMO_001",
          LOW: this.currentStore,
          HIGH: "",
          LOW_FLAG: "0",
        },
      ];

      //  let current = [];
      this.axios
        .post(
          "/" +
            this.$ajaxPrefix.consumer +
            "/bw/BwApi/getBirthdayRepurchaseAnalysis",
          requestData
        )
        .then((res) => {
          this.vipLoading = false;
          if (res && res.data && res.data.data) {
            let temp = res.data.data;
            this.birthdayRepurchaseAnalysisData = temp[0];
          
          }
        });
    },
  },
};
